import React, { useState } from "react";
import "./styles/carousel.css";
import { IoChevronForwardSharp } from "react-icons/io5";
import { IoChevronBackSharp } from "react-icons/io5";
import { ruletaData } from "./ruletaData";
import CarouselItem from "./CarouselItem.jsx";

function Carousel() {
  const numItems = ruletaData.length;

  const widthInner = 100 * numItems;
  const [index, setIndex] = useState(0);
  const move = 100 / numItems;

  function moveRight() {
    if (index - move < 95 * -1) setIndex(0);
    else {
      setIndex(index - move);
    }
  }

  function moveLeft() {
    if (index + move > 5)
      setIndex(move * (ruletaData.length - 1) * -1); //To move right is negative
    else {
      setIndex(index + move);
    }
  }

  return (
    <div id="proyectos-section" className="proyectos-section">
      <h1 className="tittle-section height">Proyectos</h1>
      <div className="carousel">
        <div
          className="carousel-inner"
          style={{
            transform: `translateX(${index}%)`,
            width: `${widthInner}%`,
          }}
        >
          {ruletaData.map((image) => (
            <CarouselItem image={image} key={image.tittle} />
          ))}
        </div>
        <div className="buttons-container">
          <button
            className="btn-carousel back"
            type=""
            onClick={() => moveLeft()}
          >
            <IoChevronBackSharp className="icon-carousel" />
          </button>
          <button
            className="btn-carousel forward"
            type=""
            onClick={() => moveRight()}
          >
            <IoChevronForwardSharp className="icon-carousel" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Carousel;
