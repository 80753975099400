import React from "react";
import { servicesBarData } from "./servicesBarData";
import "./styles/servicesData.css";
import { Link } from "react-router-dom";

export default function () {
  var key = 0;

  return (
    <div>
      <div id="services-bar" className="services-bar">
        {servicesBarData.map((arr) => {
          key++;
          return (
            <Link to={arr[1]} key={key}>
              {arr[0]}
            </Link>
          );
        })}
      </div>
    </div>
  );
}
