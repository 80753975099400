export const dataPages = {
  remodelaciones: {
    title: "REMODELACIONES",
    text: `CONSTRUCCIONES Y REMODELACIONES DE LOCALES COMERCIALES, NUESTRO FUERTE.
      Cada local tiene un ambiente distintivo desde su proceso constructivo hasta sus acabados,
      y eso sólo lo puede lograr, en tiempo récord, los profesionales calificados.

      Cotice con nosotros.

    `,
  },

  ingenieria: {
    title: "INGENIERÍA",
    text: `EL ÉXITO DE TODOS NUESTROS PROYECTOS ES EL ORDEN.
      Nuestros proyectos de Construcción cuentan con un seguimiento estricto de cumplimiento
      de actividades semanales para una entrega real y precisa, así también, cuenta con un equipo
      completo donde cada profesional es experto en su área.
      
      Cotice con nosotros.
      `,
  },

  arquitectura: {
    title: "ARQUITECTURA",
    text: `PARA NOSOTROS LA ARQUITECTURA ES ARTE, TÉCNICA E INGENIO.
            En Grupo Technical contamos con Diseñadores de Interiores, Arquitectos, Profesionales
            del Paisajismo y Delineantes Digitales que impulsarán las soluciones de nuestros clientes
            para desarrollar y finalmente realizar sus espacios soñados.
            
            Cotice con nosotros.
            `,
  },

  administracion_de_proyectos: {
    title: "ADMINISTRACIÓN DE PROYECTOS",
    text: `LA CLAVE DE UNA OBRA ESTÁ EN SU ADMINISTRACIÓN.
            Cada construcción que manejamos se lleva a cabo con un cronograma de actividades que agiliza el proceso
            constructivo y organiza la tarea de diferentes disciplinas que se estén ejecutando a la par. Esta información es
            facilitada al cliente de manera digital para que el mismo tenga un seguimiento del proyecto en tiempo real esté
            donde esté. Contamos con profesionales capacitados con más de 20 años de experiencia, lo que nos permite ser
            una de las empresas más ágiles del mercado de la construcción en Panamá.
            
            Cotice con nosotros.
            `,
  },
};
