export const texts = {
  tittle:
    "Abarcamos todas las disciplinas existentes en cuanto a Construcción se trata.",
  services:
    "CONSTRUCCIÓN EN GENERAL - PINTURAS - TECHOS - PISOS " +
    "- TRABAJOS EN GYPSUM - ALBAÑILERÍA - CARPINTERÍA - " +
    "EBANISTERÍA - ACABADOS - MOBILIARIOS - PLOMERÍA - " +
    "ELECTRICIDAD - ADMINISTRACIÓN DE PROYECTOS - " +
    "CRONOGRAMAS DE ACTIVIDADES - AIRES ACONDICIONADOS - " +
    "PROYECTOS INDUSTRIALES.",
  textNumbers:
    "Para una rápida atención: Respuestas inmediatas a nuestros números:",
  phoneNumbers: ["+507 6765-6663", "+507 6678-5092", "+507 6515-8784"],
  email: "technicalarquitectura@gmail.com",
};
