import CONSTRUCCION from "./images/CONSTRUCCION.jpg";
import REMODELACIONES from "./images/REMODELACIONES.jpg";
import DISENO_DE_INTERIORES from "./images/DISENO_DE_INTERIORES.jpg";
import DIBUJO_DE_PLANOS from "./images/DIBUJO_DE_PLANOS.jpg";
import ADMINISTRACION_DE_OBRAS from "./images/ADMINISTRACION_DE_OBRAS.jpg";
import ARQUITECTURA from "./images/ARQUITECTURA.jpg";
import ANIMACIONES from "./images/ANIMACIONES.png";
import INGENIERIA from "./images/INGENIERIA.jpg";

export const serviciosData = [
  {
    nombre: "CONSTRUCCIÓN",
    img: CONSTRUCCION,
  },
  {
    nombre: "REMODELACIONES",
    img: REMODELACIONES,
  },
  {
    nombre: "DISEÑO DE INTERIORES",
    img: DISENO_DE_INTERIORES,
  },
  {
    nombre: "DIBUJO DE PLANOS",
    img: DIBUJO_DE_PLANOS,
  },
  {
    nombre: "ADMINISTRACIÓN DE PROYECTOS",
    img: ADMINISTRACION_DE_OBRAS,
  },
  {
    nombre: "ARQUITECTURA",
    img: ARQUITECTURA,
  },
  {
    nombre: "ANIMACIONES",
    img: ANIMACIONES,
  },
  {
    nombre: "INGENIERÍA",
    img: INGENIERIA,
  },
];

export const text =
  "Todos nuestros servicios cuentan con" +
  " una excelente atención personalizda y proyectos" +
  " cronometrados por cuadros de actividades elaborados" +
  " por nuestro grupo de profesionales del campo" +
  " constructivo. Hemos trabajado para marcas importantes" +
  " de restaurantes, locales comerciales y proyectos" +
  " públicos";
