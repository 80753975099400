import React from "react";
import { useNavigate } from "react-router";
import { dataPages } from "./dataPages";
import "./styles/pages.css";
import logo from "./images/logo.jpeg";

// Import Images
import img1 from "./images/construccion/1.jpg";
import img2 from "./images/construccion/2.jpg";
import img3 from "./images/construccion/3.jpg";
import img4 from "./images/construccion/4.jpg";
import img5 from "./images/construccion/5.jpg";
import img6 from "./images/construccion/6.jpg";
import img7 from "./images/construccion/7.jpg";
import img8 from "./images/construccion/8.jpg";
import img9 from "./images/construccion/9.jpg";
import img10 from "./images/construccion/10.jpg";

function Template({ page }) {
  console.log(page);

  const { title, text } = dataPages[page];

  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];

  // Create an array to get all images of each template
  const nums = [];
  for (var i = 1; i < 6; i++) {
    nums.push(i);
  }

  const navigate = useNavigate();

  function sendToHome(event) {
    event.preventDefault();
    navigate("/");
  }

  return (
    <div>
      <div className="tittle-container">
        <h1 className="tittle-page">{title}</h1>
        <button className="btn-pages" onClick={(e) => sendToHome(e)}>
          <img className="logo-page" src={logo} alt="" />
        </button>
      </div>
      <div className="images-template-container">
        {nums.map((num) => {
          return (
            <div className="field-image-container" key={num}>
              <img
                className="image-page-back"
                src={images[num - 1]}
                alt="imagen"
                style={{ left: `${(num - 1) * 20}vw` }}
              />
              <img
                className={`image-page-front`}
                src={images[num + 4]}
                alt="imagen"
                style={{ left: `${(num - 1) * 20}vw` }}
              />
            </div>
          );
        })}
      </div>
      <div className="container-text-page">
        <p className="text-page">
          <h2>
            Construcciones y Remodelaciones de Locales Comerciales, nuestro
            fuerte.
          </h2>
          <br />
          <p>
            Cada local tiene un ambiente distintivo desde su proceso
            constructivo hasta sus acabados, y eso sólo lo puede lograr, en
            tiempo récord, los profesionales calificados.
            <br />
            <br />
            Cotice con nosotros.
          </p>
        </p>
      </div>
    </div>
  );
}

export default Template;
