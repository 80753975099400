import React from "react";
import { useNavigate } from "react-router";
import logo from "./images/TechnicalLogo.jpeg";
import { GrInstagram } from "react-icons/gr";
import { BsLinkedin } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebookSquare } from "react-icons/fa";
import "./styles/header.css";

function Header() {
  const navigate = useNavigate();

  function sendToHome(event) {
    event.preventDefault();
    navigate("/");
  }

  return (
    <div className="contenedor-header">
      <img
        onClick={(e) => sendToHome(e)}
        className="logo"
        src={logo}
        alt="Logo de technical"
      />
      {/* <img className="flag" src={flag} alt="bandera de panama"/> */}

      <div className="icons-container">
        <a
          href="https://instagram.com/grupotechnical_?utm_medium=copy_link"
          target="_blank"
        >
          <GrInstagram />
        </a>

        <a
          href="https://www.linkedin.com/company/technical-arch-studio/"
          target="_blank"
        >
          <BsLinkedin />
        </a>

        <a href="https://wa.me/+50767656663">
          <IoLogoWhatsapp />
        </a>

        <a href="https://m.facebook.com/technicalarquitectura/" target="_blank">
          <FaFacebookSquare />
        </a>
      </div>
    </div>
  );
}

export default Header;
