import React, { useState } from "react";
import "./styles/construya.css";
import { texts } from "./texts";
import axios from "axios";

function Construya() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cel, setCel] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [asunto, setAsunto] = useState("");
  const [message, setMessage] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    const config = {
      method: "POST",
      url: "https://e5f4-2800-e2-2680-1e00-65ae-2bca-f452-e0d0.ngrok.io",
      data: {
        name,
        email,
        cel,
        empresa,
        asunto,
        message,
      },
    };

    try {
      await axios(config);
    } catch (err) {}
  }
  const key = 0;
  return (
    <div id="construya-section">
      <div id="title-construya" className="tittle-construya height">
        <h1>Construya Con Nosotros</h1>
      </div>
      <div id="construya" className="construya">
        <div id="text-construya" className="text-construya">
          <h4>{texts.tittle}</h4>
          <p>{texts.services}</p>
          <h4>{texts.textNumbers}</h4>
          <p>
            {texts.phoneNumbers.map((phone) => (
              <>
                {phone}
                <br />
              </>
            ))}
          </p>
          <h5>{texts.email}</h5>
        </div>
        <div id="form-container" className="form-container">
          <form
            action="https://formsubmit.co/juliancamiloalvarez77@gmail.com"
            method="POST"
            className="form"
          >
            <label htmlFor="name">Nombre</label>
            <input
              type="text"
              name="name"
              required
              className="input-form"
              placeholder="Example"
              onChange={(e) => setName(e.target.value)}
            />

            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              required
              className="input-form"
              placeholder="example@example.com"
              onChange={(e) => setEmail(e.target.value)}
            />

            <label htmlFor="tel">Teléfono</label>
            <input
              type="text"
              name="tel"
              required
              className="input-form"
              placeholder="7845..."
              onChange={(e) => setCel(e.target.value)}
            />

            <label htmlFor="empresa">Empresa</label>
            <input
              type="text"
              name="empresa"
              required
              className="input-form"
              placeholder="Example"
              onChange={(e) => setEmpresa(e.target.value)}
            />

            <label htmlFor="asunto">Asunto</label>
            <input
              type="text"
              name="asunto"
              required
              className="input-form"
              placeholder="Example"
              onChange={(e) => setAsunto(e.target.value)}
            />

            <label htmlFor="message">Mensaje</label>
            <textarea
              name="message"
              cols="30"
              rows="8"
              className="input-form textarea"
              placeholder="Mensaje..."
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <button
              type="submit"
              className="button-form"
              onClick={handleSubmit}
            >
              Enviar
            </button>
          </form>
          {/* <div>
            <img src={logo} className="construya-logo" alt="technical-logo" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Construya;
