import "./App.css";
import Servicios from "./components/servicios/Servicios";
import Home from "./components/Home/Home";
import Carousel from "./components/Ruleta/Carousel.jsx";
import Construya from "./components/Construya/Construya";
import Grupo from "./components/Grupo/Grupo";
import Historia from "./components/Historia/Historia";
import { Routes, Route } from "react-router-dom";
import Template from "./components/OnePages/Template";
import Template2 from "./components/OnePages/Template2";
import Template3 from "./components/OnePages/Template3";
import Template4 from "./components/OnePages/Template4";
import Header from "./components/Header/Header";
import ServicesBar from "./components/ServicesBar/ServicesBar";
import { useEffect } from "react";

// require('bootstrap')

function App() {

  useEffect(() => {
    document.title = 'Grupo Technical';
  }, []); 

  return (
    <div className="App">
      <Header />
      <ServicesBar />
      {/* <Navbar /> TODO:Delete if not needed */}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Home />
              <Historia />
              <Grupo />
              <Servicios />
              <Carousel />
            </>
          }
        />
        <Route
          exact
          path="/remodelaciones"
          element={<Template page="remodelaciones" />}
        />
        <Route
          exact
          path="/ingenieria"
          element={<Template2 page="ingenieria" />}
        />
        <Route
          exact
          path="/arquitectura"
          element={<Template3 page="arquitectura" />}
        />
        <Route
          exact
          path="/administracion_de_proyectos"
          element={<Template4 page="administracion_de_proyectos" />}
        />
      </Routes>
      <Construya />
    </div>
  );
}

export default App;
