import React from "react";
import { useNavigate } from "react-router";
import { dataPages } from "./dataPages";
import "./styles/pages.css";
import logo from "./images/logo.jpeg";

// Import Images
import img1 from "./images/adminProyectos/1.jpg";
import img2 from "./images/adminProyectos/2.jpg";
import img3 from "./images/adminProyectos/3.jpg";
import img4 from "./images/adminProyectos/4.jpg";
import img5 from "./images/adminProyectos/5.jpg";
import img6 from "./images/adminProyectos/6.jpg";
import img7 from "./images/adminProyectos/7.jpg";
import img8 from "./images/adminProyectos/8.jpg";
import img9 from "./images/adminProyectos/9.jpg";
import img10 from "./images/adminProyectos/10.jpg";

function Template4({ page }) {
  const { title, text } = dataPages[page];

  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];

  // Create an array to get all images of each template
  const nums = [];
  for (var i = 1; i < 6; i++) {
    nums.push(i);
  }

  const navigate = useNavigate();

  function sendToHome(event) {
    event.preventDefault();
    navigate("/");
  }

  return (
    <div>
      <div className="tittle-container">
        <h1 className="tittle-page">{title}</h1>
        <button className="btn-pages" onClick={(e) => sendToHome(e)}>
          <img className="logo-page" src={logo} alt="" />
        </button>
      </div>
      <div className="images-template-container">
        {nums.map((num) => {
          return (
            <div className="field-image-container" key={num}>
              <img
                className="image-page-back"
                src={images[num - 1]}
                alt="imagen"
                style={{ left: `${(num - 1) * 20}vw` }}
              />
              <img
                className={`image-page-front`}
                src={images[num + 4]}
                alt="imagen"
                style={{ left: `${(num - 1) * 20}vw` }}
              />
            </div>
          );
        })}
      </div>
      <div className="container-text-page">
        <p className="text-page">
          <h2>La clave de una Obra está en su Administración.</h2>
          <br />
          <p>
            Cada construcción que manejamos se lleva a cabo con un cronograma de
            actividades que agiliza el proceso constructivo y organiza la tarea
            de diferentes disciplinas que se estén ejecutando a la par. Esta
            información es facilitada al cliente de manera digital para que el
            mismo tenga un seguimiento del proyecto en tiempo real esté donde
            esté. Contamos con profesionales capacitados con más de 20 años de
            experiencia, lo que nos permite ser una de las empresas más ágiles
            del mercado de la construcción en Panamá.
            <br />
            <br />
            Cotice con nosotros.
          </p>
        </p>
      </div>
    </div>
  );
}

export default Template4;
