import React from "react";
import "./styles/servicios.css";
import { serviciosData, text } from "./serviciosData";
import "../../styles/generic.css";

function Servicios() {
  var key = 0;

  return (
    <section id="servicios">
      <div id="section-servicios">
        <div id="title-servicios">
          <h1 className="tittle-section height">SERVICIOS</h1>
        </div>
        <div className="grid-text-container" id="grid-text-servicios">
          <div className="text-servicios-container">
            <p className="p-text" id="p-services">
              {text}
            </p>
          </div>
          <div className="section-servicios padding-0">
            {serviciosData.map((servicio) => {
              key++;
              return (
                <div key={key} className="image-container-wrapper">
                  <h4>{servicio.nombre}</h4>
                  <figure className="image-container">
                    <img
                      src={servicio.img}
                      className="image-servicios"
                      alt={servicio.nombre}
                    />
                  </figure>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Servicios;
