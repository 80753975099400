import React from "react";
import "./styles/grupo.css";
import elizabeth from "./images/elizabeth.jpg";
import cristel from "./images/cristel.jpg";
import equipo from "./images/equipo.jpg";
import olmedo from "./images/olmedoRecorte.jpeg";

export default function Grupo() {
  return (
    <div id="grupo" className="grupo-section">
      <h1 className="tittle-section height">Grupo Technical</h1>
      <div className="grupo-container">
        <div className="divs-container">
          <div className="pos1-grupo groups">
            <img src={cristel} alt={"Image"} />
            <div>
              <h3>Arq. Cristel Manfredo</h3>
              <p className="paragraph">
                Líder Comercial y Coordinadora de Proyectos del departamento de
                Arquitectura de Technical.
              </p>
            </div>
          </div>
          <div className="pos2-grupo groups">
            <img src={elizabeth} alt={"Image"} />

            <div>
              <h3>Arq. Elizabeth Galvis</h3>
              <p className="paragraph">
                Project Manager en el departamento de Ingeniería Civil de
                Technical.
              </p>
            </div>
          </div>
        </div>
        <div className="divs-container">
          <div className="pos3-grupo groups">
            <img src={olmedo} alt={"Image"} />
            <div>
              <h3>Ing. Olmedo Cáceres</h3>
              <p className="paragraph">
                Ingeniero Industrial Gerente de Proyectos Generales de
                Technical.
              </p>
            </div>
          </div>
          <div className="pos4-grupo groups">
            <img src={equipo} alt={"Image"} />
            <div>
              <h3>Equipo Técnico</h3>
              <p className="paragraph">
                + 20 Profesionales en Gypsum, Herrería, Albañilería,
                Ebanistería, Sistemas Industriales y más.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
