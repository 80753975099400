import React from "react";

function CarouselItem({ image }) {
  return (
    <div id="carousel-item">
      <h3>{image.tittle}</h3>
      <h5>{image.text}</h5>
      <img src={image.src} alt={image.tittle} />
    </div>
  );
}

export default CarouselItem;
