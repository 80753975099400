import React from "react";
import "./styles/home.css";
import arquitectura from "./HomeImages/arquitectura.jpg";
import ingenieriaCivil from "./HomeImages/ingenieriaCivil.jpg";
import image from "./HomeImages/Image.jpg";

function Home() {
  return (
    <section id="home">
      <div>
        <p>
          Realizamos proyectos de construcción con las metodologías más
          innovadoras del mercado ofreciendo un producto final de calidad para
          la felicidad del cliente.
        </p>
        <button className="button-form">
          <a href="#construya" style={{ color: "white" }}>
            Contáctenos
          </a>
        </button>
      </div>

      <div>
        <img src={image} alt="" />
      </div>
    </section>
  );
}

export default Home;

/**
 * function Home() {
  return (
    <section id="home">
      <div>
        <div className="info-home">
          <div className="tittle-home left-top">
            <h4>INGENERÍA CIVIL</h4>
          </div>
          <div className="tittle-home left">
            <h4>ARQUITECTURA</h4>
          </div>
          <div className="tittle-home right">
            <h4>DISEÑO DE INTERIORES</h4>
          </div>

          <img className="image-left" src={ingenieriaCivil} alt="" />
          <img className="image-middle" src={arquitectura} alt="Home image" />
          <img className="image-right" src={sala} alt="" />
        </div>
      </div>
    </section>
  );
}
 */
