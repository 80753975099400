import HELADO from "./images/Helado.png";
import JUPITER from "./images/JUPITER.jpg";
import AT from "./images/asesoriaTecnica.jpg";
import MURO from "./images/MURO.jpg";
import cielosYP from "./images/cielosYparedes.jpg";
import CRYPTO from "./images/cryptoZone.jpg";
import POLA from "./images/haciendaPola.jpg";
import mercadoM from "./images/mercadoMunicipal.jpg";
import AMPM from "./images/tiendaAMPM.jpg";
import VAYVEN from "./images/VAYVEN.jpg";

export const ruletaData = [
  {
    tittle: "ROAD MARKET PANAMÁ",
    src: HELADO,
    text: "Diseño y construcción",
  },
  {
    tittle: "AMPM JÚPITER",
    src: JUPITER,
    text: "Remodelación comercial",
  },
  {
    tittle: "ASESORÍA TÉCNICA",
    src: AT,
    text: "En cualquier provincia del país",
  },
  {
    tittle: "MURO ANCLADO DE 400 M",
    src: MURO,
    text: "Administración de proyecto",
  },
  {
    tittle: "CIELOS Y PAREDES",
    src: cielosYP,
    text: "Diseño y construcción en gypsum",
  },
  {
    tittle: "CRYPTO ZONE",
    src: CRYPTO,
    text: "Diseño y construcción",
  },
  {
    tittle: "HACIENDA POLA",
    src: POLA,
    text: "Diseño y construcción",
  },
  {
    tittle: "MERCADO MUNICIPAL DE CHILIBRE",
    src: mercadoM,
    text: "Diseño arquitéctonico",
  },
  {
    tittle: "TIENDA AMPM PLAZA MIDTOWN",
    src: AMPM,
    text: "Construcción y administración",
  },
  {
    tittle: "TIENDA VA & VEN DE TERPEL",
    src: VAYVEN,
    text: "Dibujo de planos",
  },
];
