import React from "react";
import "./styles/historia.css";
import historia from "./images/historia.jpg";

export default function Historia() {
  return (
    <div className="historia-section">
      <h1 className="tittle-section height">Historia</h1>
      <div className="historia-wrapper">
        <img src={historia} className="image-historia" alt="Imagen Historia" />
        <div className="p-historia-container">
          <p>
            Fundado el 2015, somos una empresa panameña de Arquitectura e
            Ingeniería Civil que brinda servicios de Construcción de Obras,
            conceptualizaciones para Ante-proyectos, Inspección en Proyectos,
            Suministro de Diseño Arquitectónico, Infoarquitectura, Modelados 3D,
            Dibujo de planos técnicos, Confección de Mobiliarios, Remodelaciones
            Comerciales y Residenciales.
          </p>
        </div>
      </div>
    </div>
  );
}
